<template>
  <KCrudLayout
    :filters.sync="filters"
    :search.sync="searchQuery"
  >
    <template #header>
      {{ $tc('shift.title', 2) }}
    </template>
    <template #flow-actions>
      <v-btn
        color="primary"
        depressed
        tile
        @click="handleOpenCreateDialog"
      >
        {{ $t('actions.createResource', { resource: $tc('shift.title', 1) }) }}
      </v-btn>
      <ShiftForm
        v-model="createDialog"
        :request="createRequest"
        :title="$tc('shift.title',1)"
        :values="createFormValues"
        @change="$refs.table.reload()"
      />
    </template>
    <template #filters="{ attrs, on }">
      <ShiftsFilter
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <template #view.list>
      <k-crud-table
        ref="table"
        :headers="crudHeaders"
        :index-request="indexRequest"
        :params="filters"
        :search="searchQuery"
        language-prefix="shift.fields"
        resource="shift"
        @click:row="$router.push({ name: 'shift.show', params: { shiftId: $event.id } })"
      >
        <template #item.shiftStatusIcon="{ item }">
          <v-icon
            v-if="item.shiftStatusId === shiftStatus.NEW"
            color="error"
          >
            $hexagon
          </v-icon>
          <v-icon
            v-else-if="item.shiftStatusId === shiftStatus.FULFILLED"
            color="success"
          >
            $triangle
          </v-icon>
        </template>
        <template #item.employerName="{ item }">
          <div>{{ item.employerName }}</div>
          <div class="font-italic font-weight-light">
            {{ item.locationName }}, {{ item.departmentName }}
          </div>
        </template>
        <template #item.from="{ item }">
          <div>{{ item.dateText }}</div>
          <div class="font-italic font-weight-light">
            {{ item.timeText }}
          </div>
        </template>
        <template #item.shiftStatusId="{ item }">
          <div :class="`shift-status shift-status--${item.shiftStatusId}`">
            {{ $t(`shift.status.${item.shiftStatusId}`) }}
          </div>
          <div v-if="item.statusDateText">
            {{ item.statusDateText }}
          </div>
        </template>
        <template #item.invitationOpenCount="{ item }">
          <ShiftReactions
            v-if="item.invitedCount"
            :accepted="item.invitationAcceptedCount"
            :open="item.invitationOpenCount"
            :refused="item.invitationRefusedCount"
          />
        </template>
        <template #actions="{ item }">
          <ShiftInlineActions
            :is-dialog-open="dialogOpen === item.id"
            :shift="item"
            @change="$refs.table.reload()"
            @input="dialogOpen = $event"
            @openDialog="openDialog"
          />
        </template>
      </k-crud-table>
    </template>
  </KCrudLayout>
</template>

<script>
import { shiftStatus } from '@/application/enums/shiftStatus.js';
import eventBus from '@/application/eventBus.ts';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import Shift from '@/application/models/Shift.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index } from '@/modules/shift/api/shift.js';
import ShiftForm from '@/modules/shift/components/ShiftForm.vue';
import ShiftInlineActions from '@/modules/shift/components/ShiftInlineActions.vue';
import ShiftReactions from '@/modules/shift/components/ShiftReactions.vue';
import ShiftsFilter from '@/modules/shift/components/ShiftsFilter.vue';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'ShiftIndex',
  components: {
    ShiftInlineActions,
    ShiftsFilter,
    ShiftReactions,
    ShiftForm,
    KCrudTable,
    KCrudLayout,
  },
  mixins: [querySyncMixin],
  data() {
    return {
      filters: {
        shiftStatuses: [shiftStatus.NEW, shiftStatus.INVITED, shiftStatus.PROPOSED],
      },
      searchQuery: '',
      createDialog: false,
      createFormValues: new Shift(),
      dialogOpen: false,
    };
  },
  computed: {
    shiftStatus: () => shiftStatus,
    crudHeaders() {
      return [
        {
          text: '',
          value: 'shiftStatusIcon',
          align: 'center',
          sortable: false,
          filterable: false,
          groupable: false,
          width: '1%',
          cellClass: 'no-table-separator',
        },
        {
          value: 'employerName',
          language: 'employerId',
          cellClass: 'no-table-separator',
        },
        {
          value: 'positionName',
          language: 'positionId',
        },
        {
          value: 'from',
          language: 'period',
        },
        {
          value: 'shiftStatusId',
          language: 'shiftStatusId',
        },
        {
          value: 'invitationOpenCount',
          language: 'reactions',
          width: 150,
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'shift.index' },
        text: this.$tc('shift.title', 2),
      },
    ]);
  },
  methods: {
    handleOpenCreateDialog() {
      this.createFormValues = new Shift();
      this.createDialog = true;
    },
    openDialog(shiftId) {
      this.dialogOpen = shiftId;
    },
    createRequest() {
      return create(this.createFormValues);
    },
    async indexRequest(...args) {
      const request = await index(...args);
      if (request.data.data) {
        request.data.data.map(shift => {
          shift.statusDateText = shift.statusDate ? dayjs(shift.statusDate).fromNow() : '';
          shift.dateText = shift.from ? dayjs(shift.from).format('LL') : '';

          const fromTime = dayjs(shift.from).format('LT');
          const toTime = dayjs(shift.to).format('LT');
          shift.timeText = `${fromTime} ${this.$t('global.till')} ${toTime}`;
        });
      }
      return request;
    },
  },
};
</script>
