<template>
  <v-row
    id="container"
    ref="container"
    align="center"
    justify="space-around"
  >
    <Chart
      v-if="chartSettingsSet"
      :options="mergedChartOptions"
    />
  </v-row>
</template>

<script>
import { Chart } from 'highcharts-vue';
import mergedChartOptions from '@/modules/report/mixins/mergedChartOptions.vue';

const defaultStyle = {
  // @TODO: research if it's possible to link to Vuetify instance/sass variables
  color: 'rgba(0, 0, 0, 0.87)',
  fontFamily: ['Roboto', 'serif'],
};

export default {
  name: 'KHighcharts',
  components: { Chart },
  mixins: [mergedChartOptions],
  inheritAttrs: false,
  props: {
    maxWidth: {
      type: [Number, String],
      default: null,
    },
    maxHeight: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: '66%',
    },
  },
  data() {
    return {
      chartWidth: null,
      chartHeight: null,
      chartSettingsSet: false,
    };
  },
  computed: {
    chartOptions() {
      return ({
        chart: {
          width: this.chartWidth,
          height: this.chartHeight,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          backgroundColor: 'transparent',
          style: defaultStyle,
        },
        labels: { style: defaultStyle },
        title: {
          style: defaultStyle,
          text: '',
        },
        subtitle: { style: defaultStyle },
        colors: ['#A99368', '#252525', '#A13333', '#4D5A58', '#7B8068', '#5A392D'],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            label: {
              style: defaultStyle,
            },
            dataLabels: {
              colorByPoint: false,
              enabled: true,
              style: defaultStyle,
            },
          },
          column: {
            allowPointSelect: true,
            cursor: 'pointer',
            label: {
              style: defaultStyle,
            },
            dataLabels: {
              colorByPoint: false,
              enabled: true,
              style: defaultStyle,
            },
          },
        },
        tooltip: {
          shared: true,
          style: defaultStyle,
        },
      });
    },
  },
  async mounted() {
    // @TODO: make chart responsive on window resize
    this.chartSettingsSet = false;
    const container = this.$refs.container;
    this.chartWidth = Math.max(Math.min(container.clientWidth, container.scrollWidth, this.maxWidth), 0);
    // @TODO: seems to be working as hoped for -- however logs show 0 as chartHeight (from parent) even though it's not.
    // this.chartHeight = Math.max(Math.min(container.clientHeight, container.scrollHeight, this.maxHeight), 0);
    this.chartHeight = this.maxHeight ? Math.max(Math.min(container.clientHeight, container.scrollHeight, this.maxHeight), 0) : this.height;
    this.chartSettingsSet = true;
  },
};
</script>
