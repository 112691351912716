<script>
import merge from 'lodash.merge';

export default {
  name: 'MergedChartOptions',
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  computed: {
    mergedChartOptions() {
      return merge({}, this.chartOptions, this.options);
    },
  },
};
</script>
