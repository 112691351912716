<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-overflow
    offset-y
    open-on-hover
    transition="scale-transition"
  >
    <template #activator="{ on }">
      <v-row
        class="bar ma-0"
        v-on="on"
      >
        <div
          v-if="refused > 0"
          :style="{ width: `${refused/total*100}%` }"
          class="child refused red darken-3"
        />
        <div
          v-if="accepted > 0"
          :style="{ width: `${accepted/total*100}%` }"
          class="child accepted green darken-3"
        />
        <div
          v-if="open > 0"
          :style="{ width: `${open/total*100}%` }"
          class="child open blue darken-3"
        />
      </v-row>
    </template>
    <v-card
      height="200"
      width="200"
    >
      <PieChart :options="chartOptions" />
    </v-card>
  </v-menu>
</template>

<script>
import colors from 'vuetify/lib/util/colors';
import PieChart from '@/modules/report/charts/PieChart.vue';

export default {
  name: 'ShiftReactions',
  components: { PieChart },
  props: {
    accepted: {
      required: true,
      type: Number,
    },
    open: {
      required: true,
      type: Number,
    },
    refused: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    total: (vm) => vm.open + vm.refused + vm.accepted,
    chartOptions() {
      return {
        chart: {
          height: 200,
          width: 200,
        },
        tooltip: {
          pointFormat: '{point.y}',
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [{
          data: [
            {
              name: this.$t('shift.reactions.refused'),
              color: colors.red.darken3,
              y: this.refused,
            }, {
              name: this.$t('shift.reactions.accepted'),
              color: colors.green.darken3,
              y: this.accepted,
            }, {
              name: this.$t('shift.reactions.open'),
              color: colors.blue.darken3,
              y: this.open,
            }],
        }],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bar {
  height: 20px;
  width:  100%;

  .child {
    height: 100%;
  }

  .child:not(:first-child) {
    box-shadow: -1px 0px 0px 0px white;
  }

  .child:first-child {
    border-top-left-radius:    10px;
    border-bottom-left-radius: 10px;
  }

  .child:last-child {
    border-top-right-radius:    10px;
    border-bottom-right-radius: 10px;
  }
}
</style>
