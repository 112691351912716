<template>
  <k-field-group language-prefix="shift.filterFields">
    <k-sidebar
      v-bind="$attrs"
      :save-button-text="$t('shift.actions.applyFilter')"
      :title="$t('shift.filterTitle')"
      right
      fixed
      temporary
      width="400"
      :cancel-button-text="$t('actions.cleanFilters')"
      :with-cancel-button="true"
      v-on="$listeners"
      @click:confirm="handleFilterClick"
      @click:cancel="handleCleanFilter"
    >
      <EmployerAutocomplete
        v-model="form.employerId"
        field="employerId"
        grid="col-sm-12 mt-2 pt-1"
        hide-details
        :return-object="false"
        item-text="name"
        item-value="id"
      />
      <KCheckboxGroup
        v-model="form.shiftStatuses"
        :items="selectableShiftStatuses"
        :persistent-hint="false"
        dense
        field="status"
        hide-details
        multiple
      />
      <KDateField
        v-model="form.from"
        field="dateFrom"
        grid="col-sm-12 mt-4 pt-1"
        hide-details
      />
      <KDateField
        v-model="form.to"
        field="dateTo"
        grid="col-sm-12 mt-4 pt-1"
        hide-details
      />
    </k-sidebar>
  </k-field-group>
</template>

<script>
import { shiftStatus } from '@/application/enums/shiftStatus.js';
import KCheckboxGroup from '@/components/crud/fields/KCheckboxGroup.vue';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import EmployerAutocomplete from '@/modules/employer/components/EmployerAutocomplete.vue';

export default {
  name: 'ShiftsFilter',
  components: {
    KSidebar,
    KFieldGroup,
    KCheckboxGroup,
    KDateField,
    EmployerAutocomplete,
  },
  inheritAttrs: false,
  props: {
    filters: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      shiftStatuses: [],
      employerId: null,
      from: '',
      to: '',
    },
  }),
  computed: {
    selectableShiftStatuses() {
      return Object.values(shiftStatus).map(statusId => ({
        text: this.$t(`shift.status.${statusId}`),
        value: statusId,
      }));
    },
  },
  watch: {
    filters: {
      handler(values) {
        this.form = { ...this.form, ...values };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFilterClick() {
      this.$emit('update:filters', this.form);
      this.$emit('input', false);
    },
    handleCleanFilter(){
      this.form = {};
      this.$emit('update:filters', this.form);
    },
  },
};
</script>
