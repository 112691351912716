import dayjs from '@/plugins/dayjs';
import Model from '@/application/models/model.js';

class Shift extends Model {
  id = 0;
  employer = null;
  location = null;
  department = null;
  position = null;
  date = '';
  from = '';
  to = '';
  matchModeId = 1;

  /*Read only*/
  shiftHandlingId = 0;
  shiftStatusId = 0;
  candidate = null;
  statusDate = '';

  mapForRequest() {
    /**
     * The date field is front-end only the api has 2 datetime stamps.
     */
    const fromTime = dayjs(`${this.date} ${this.from}`);
    let toTime = dayjs(`${this.date} ${this.to}`);
    if (fromTime.isAfter(toTime)) {
      toTime = toTime.add(1, 'day');
    }

    return {
      employerId: this.employer?.id,
      locationId: this.location?.id,
      departmentId: this.department?.id,
      positionId: this.position?.id,
      from: fromTime.format('YYYY-MM-DD HH:mm:ss'),
      to: toTime.format('YYYY-MM-DD HH:mm:ss'),
      matchModeId: this.matchModeId,
      shiftHandlingId: this.shiftHandlingId,
    };
  }

  mapResponse(data) {
    const date = dayjs(data.from).format('YYYY-MM-DD');
    const from = dayjs(data.from).format('HH:mm');
    const to = dayjs(data.to).format('HH:mm');
    return super.mapResponse({
      ...data,
      from,
      to,
      date,
    });
  }
}

export default Shift;
