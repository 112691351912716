<template>
  <div>
    <dropdown-button
      v-if="canWithdraw"
      @click.stop="openUpdate"
    >
      {{ $t('shift.actions.edit') }}
      <template #list>
        <v-list>
          <v-list-item
            link
            @click="handleWithdraw"
          >
            {{ $t('shift.actions.withdraw') }}
          </v-list-item>
        </v-list>
      </template>
    </dropdown-button>
    <v-btn
      v-else
      color="primary"
      outlined
      tile
      @click.stop="openUpdate"
    >
      {{ $t('shift.actions.edit') }}
    </v-btn>

    <ShiftForm
      :value="isDialogOpen"
      :request="updateRequest"
      :title="$tc('shift.title',1)"
      :values="updateFormValues"
      is-update-form
      @input="closeDialog"
      @change="$emit('change')"
    />
  </div>
</template>

<script>
import { shiftStatus } from '@/application/enums/shiftStatus.js';
import Shift from '@/application/models/Shift.js';
import DropdownButton from '@/components/DropdownButton.vue';
import { show, update, withdraw } from '@/modules/shift/api/shift.js';
import ShiftForm from '@/modules/shift/components/ShiftForm.vue';

export default {
  name: 'ShiftInlineActions',
  components: {
    ShiftForm,
    DropdownButton,
  },
  inheritAttrs: false,
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    shift: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    updateFormValues: new Shift(),
  }),
  computed: {
    canWithdraw() {
      return this.shift.shiftStatusId !== shiftStatus.WITHDRAWN && this.shift.shiftStatusId !== shiftStatus.FULFILLED;
    },
  },
  methods: {
    async openUpdate() {
      this.updateFormValues = new Shift();
      const shiftShowRequest = await show(this.shift.id);
      this.updateFormValues.mapResponse(shiftShowRequest.data.data);
      this.$emit('openDialog', this.shift.id);
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    async handleWithdraw() {
      await this.withdrawRequest();

      this.$emit('change');
    },
    withdrawRequest() {
      return withdraw(this.shift.id);
    },
    closeDialog() {
      this.$emit('input', false);
    },
  },
};
</script>
