<template>
  <KHighcharts
    v-bind="$attrs"
    :options="mergedChartOptions"
    v-on="$listeners"
  />
</template>

<script>
import KHighcharts from '@/modules/report/base/KHighcharts.vue';
import mergedChartOptions from '@/modules/report/mixins/mergedChartOptions.vue';

export default {
  name: 'PieChart',
  components: { KHighcharts },
  mixins: [mergedChartOptions],
  inheritAttrs: false,
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
        },
        plotOptions: {
          pie: {
            dataLabels: {
              format: '<strong>{point.name}</strong>: {point.percentage:.1f} %',
            },
          },
        },
        tooltip: {
          pointFormat: '<strong>{point.y}</strong> ({point.percentage:.1f}%)',
        },
        title: false,
        subtitle: false,
      },
    };
  },
};
</script>
