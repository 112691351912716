<template>
  <k-field-group language-prefix="shift.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="panels"
      add-another
      v-on="$listeners"
    >
      <template #panel.0>
        <v-row
          no-gutters
          class="pr-2"
        >
          <EmployerAutocomplete
            v-model="values.employer"
            field="employerId"
            required
          />
          <LocationAutocomplete
            v-if="values.employer"
            v-model="values.location"
            :employer-id="values.employer.id"
            field="locationId"
            required
          />
          <DepartmentAutocomplete
            v-if="values.location"
            v-model="values.department"
            :location-id="values.location.id"
            field="departmentId"
          />
          <PositionAutocomplete
            v-model="values.position"
            field="positionId"
            required
          />
          <KDateField
            v-model="values.date"
            field="date"
            required
          />
          <KTimeField
            v-model="values.from"
            field="from"
            required
          />
          <KTimeField
            v-model="values.to"
            field="to"
            required
          />
          <KRadioGroup
            v-model="values.matchModeId"
            :items="matchModes"
            field="matchModeId"
          />
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import { shiftMatchMode } from '@/application/enums/shiftMatchMode.js';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import KTimeField from '@/components/crud/fields/KTimeField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import DepartmentAutocomplete from '@/modules/department/components/DepartmentAutocomplete.vue';
import EmployerAutocomplete from '@/modules/employer/components/EmployerAutocomplete.vue';
import LocationAutocomplete from '@/modules/location/components/LocationAutocomplete.vue';
import PositionAutocomplete from '@/modules/position/components/PositionAutocomplete.vue';

export default {
  name: 'ShiftForm',
  components: {
    KRadioGroup,
    KTimeField,
    KDateField,
    PositionAutocomplete,
    KFieldGroup,
    KFormDialog,
    DepartmentAutocomplete,
    LocationAutocomplete,
    EmployerAutocomplete,
  },
  props: {
    values: {
      type: Object,
    },
  },
  computed: {
    panels() {
      return [
        { name: this.$t('shift.tabs.default') },
      ];
    },
    matchModes() {
      return Object.values(shiftMatchMode).map((mode) => {
        return {
          text: this.$t(`shift.shiftMatchMode.${mode}`),
          value: mode,
        };
      });
    },
  },
  watch: {
    'values.hasAccess'(hasAccess) {
      if (hasAccess && !this.values.access.email) {
        this.values.access.email = this.values.contactDetail.email;
      }
    },
  },
};
</script>
